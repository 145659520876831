<template>
  <div v-if="
    !isDataBlocked &&
    isPostpaid === 'true' &&
    dataCapacity != 95367 &&
    dataCapacity != 0
  ">
    <p class="text-center">
      Your data will be reset on {{ getPostpaidResetDate() }}
    </p>
    <div class="Graph">
      <canvas id="usageGraph" width="150px" height="150px" border="1px solid red" />
      <div v-if="this.isRogers" class="UsedInfo">
        {{ getUsedDataString() }}
      </div>
      <div v-else class="UsedInfo">
        <span class="text-left data-usage-string">🥲 Data usage<br />currently unavailable&nbsp;</span>
        <span class="text-nowrap"> {{ getUsedDataString(true) }}</span>
      </div>
    </div>
    <p v-show="!this.isRogers" class="text-justify data-not-avail-text">
      You may track your data via Settings > Cellular / Data Usage or download
      My Data Manager / Data Usage Monitor. Please note that we are not
      responsible for any inaccuracies in those apps, and we appreciate your
      understanding.
    </p>
    <p class="text-center text-dark">Data Overage -- {{ topupOverage }}</p>
    <div v-if="topUpCapable">
      <div class="text-center">
        <!-- add data button  -->
        <div v-if="step === 0">
          <button style="padding: 5px 20px" v-show="step === 0" class="mybtn-blue"
            @click="openTheConfirmationMsgBoxPostpaid">
            add more data
          </button>
        </div>
        <!-- add data step 1 -->
        <div v-show="step === 1" class="animate__animated animate__fadeIn">
          <div class="d-flex flex-row justify-content-center align-items-center text-main">
            <i style="font-size: 35px" class="fas fa-minus-circle mx-5"
              :class="selectedData > 1 ? 'text-main' : 'text-grey'"
              @click="selectedData > 1 ? selectedData-- : null"></i>
            <div class="text-center">
              <div style="font-size: 50px">{{ selectedData }}</div>
              <div style="font-size: 25px; margin-top: -10px">GB</div>
            </div>
            <i style="font-size: 35px" class="fas fa-plus-circle mx-5"
              :class="selectedData < 5 ? 'text-main' : 'text-grey'"
              @click="selectedData < 5 ? selectedData++ : null"></i>
          </div>
        </div>
        <!-- add data step 2 -->
        <div v-show="step === 2">
          <div class="mb-4 text-center animate__animated animate__fadeIn">
            <div class="dataStyle">{{ selectedData }}GB</div>
            <p>
              Data Top Up:
              <span class="w-500">${{ dataAmount.amount }}</span>
            </p>
            <p v-show="gst !== 0">
              HST/GST ({{ (gst * 100).toFixed(0) + "%" }}):
              <span class="w-500">${{ dataAmount.gst }}</span>
            </p>
            <!-- check if Quebec -> show 9.975% -->
            <p v-show="pst !== 0">
              PST/QST ({{
                parseInt(pst * 100) === 9
                  ? (pst * 100).toFixed(3) + "%"
                  : (pst * 100).toFixed(0) + "%"
              }})
              <span class="w-500">${{ dataAmount.pst }}</span>
            </p>
            <p v-show="svcFee !== 0">
              Service Fee:
              <span class="w-500">${{ roundTheNumber(svcFee) }}</span>
            </p>
            <!-- <p v-show="currbal !== 0">
              Current Outstanding Balance:
              <span class="w-500">${{ roundTheNumber(currbal) }}</span>
            </p> -->
            <p class="w-500 text-main" style="font-size: 18px">
              Total: ${{ this.totalAmount }} CAD
            </p>
          </div>
        </div>
        <!-- buttons group  -->
        <div v-show="step >= 1">
          <div class="d-flex flex-row justify-content-center align-items-center animate__animated animate__fadeIn mt-4">
            <img @click="step--" class="mx-3 buttonStyle" src="../assets/left.png" width="20px" height="20px"
              alt="left icon" />
            <div v-if="step !== 2">
              <button style="padding: 5px 30px" class="mybtn-blue" @click="step++">
                next
              </button>
            </div>
            <div v-else>
              <button style="padding: 5px 20px" class="mybtn" @click="handleTopUpPostpaid()">
                confirm
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else-if="
    !isDataBlocked && isPostpaid === 'true' && dataCapacity === 95367
  ">
    <p class="text-center">You are on a unlimited data plan!</p>
  </div>
  <div v-else-if="!isDataBlocked && isPostpaid === 'true' && dataCapacity === 0">
    <p class="text-center">You are on a voice only plan!</p>
  </div>
  <div v-else-if="isPostpaid === 'false' && dataCapacity === 0">
    <p class="text-center" style="font-weight: bold">
      You are on a voice only plan!
    </p>
  </div>
  <div v-else-if="!isDataBlocked && isPostpaid === 'false'">
    <p class="text-center">
      Your data will be reset on {{ getPrepaidResetDate() }}
    </p>
    <div class="Graph">
      <canvas id="usageGraph" width="150px" height="150px" border="1px solid red" />
      <div v-if="this.isRogers" class="UsedInfo">
        {{ getUsedDataString() }}
      </div>
      <div v-else class="UsedInfo">
        <span class="text-left data-usage-string">🥲 Data usage<br />currently unavailable&nbsp;</span>
        <span class="text-nowrap"> {{ getUsedDataString(true) }}</span>
      </div>
    </div>
    <p v-show="!this.isRogers" class="text-justify data-not-avail-text">
      You may track your data via Settings > Cellular / Data Usage or download
      My Data Manager / Data Usage Monitor. Please note that we are not
      responsible for any inaccuracies in those apps, and we appreciate your
      understanding.
    </p>
    <p class="text-center text-dark">Data Top Up -- {{ dataOverage }}</p>
    <div class="text-center" v-show="dataCapacity != 0">
      <!-- add data button  -->
      <div v-if="step === 0">
        <button style="padding: 5px 20px" v-show="step === 0" class="mybtn-blue" @click="openTheConfirmationMsgBox">
          add more data
        </button>
      </div>
      <!-- add data step 1 -->
      <div v-show="step === 1" class="animate__animated animate__fadeIn">
        <div class="d-flex flex-row justify-content-center align-items-center text-main">
          <i style="font-size: 35px" class="fas fa-minus-circle mx-5"
            :class="selectedData > 1 ? 'text-main' : 'text-grey'" @click="selectedData > 1 ? selectedData-- : null"></i>
          <div class="text-center">
            <div style="font-size: 50px">{{ selectedData }}</div>
            <div style="font-size: 25px; margin-top: -10px">GB</div>
          </div>
          <i style="font-size: 35px" class="fas fa-plus-circle mx-5"
            :class="selectedData < 5 ? 'text-main' : 'text-grey'" @click="selectedData < 5 ? selectedData++ : null"></i>
        </div>
      </div>
      <!-- add data step 2 -->
      <div v-show="step === 2">
        <div class="mb-4 text-center animate__animated animate__fadeIn">
          <div class="dataStyle">{{ selectedData }}GB</div>
          <p>
            Data Top Up:
            <span class="w-500">${{ dataAmount.amount }}</span>
          </p>
          <p v-show="gst !== 0">
            HST/GST ({{ (gst * 100).toFixed(0) + "%" }}):
            <span class="w-500">${{ dataAmount.gst }}</span>
          </p>
          <!-- check if Quebec -> show 9.975% -->
          <p v-show="pst !== 0">
            PST/QST ({{
              parseInt(pst * 100) === 9
                ? (pst * 100).toFixed(3) + "%"
                : (pst * 100).toFixed(0) + "%"
            }})
            <span class="w-500">${{ dataAmount.pst }}</span>
          </p>
          <p v-show="svcFee !== 0">
            Service Fee:
            <span class="w-500">${{ roundTheNumber(svcFee) }}</span>
          </p>
          <p v-show="currbal !== 0">
            Current Balance:
            <span class="w-500">${{ roundTheNumber(currbal) }}</span>
          </p>
          <p class="w-500 text-main" style="font-size: 18px">
            Total: ${{ this.totalAmount }} CAD
          </p>
        </div>
      </div>
      <!-- buttons group  -->
      <div v-show="step >= 1">
        <div class="d-flex flex-row justify-content-center align-items-center animate__animated animate__fadeIn mt-4">
          <img @click="step--" class="mx-3 buttonStyle" src="../assets/left.png" width="20px" height="20px"
            alt="left icon" />
          <div v-if="step !== 2">
            <button style="padding: 5px 30px" class="mybtn-blue" @click="step++">
              next
            </button>
          </div>
          <div v-else>
            <button style="padding: 5px 20px" class="mybtn" @click="handleTopUp()">
              confirm
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else-if="isDataBlocked && isPostpaid === 'true'">
    <p class="text-center">
      Your data will be reset on {{ getPostpaidResetDate() }}
    </p>
    <div class="box">
      <img width="140px" height="140px" src="../assets/blocked.png" alt="warning" />
      <p style="color: #ff8080">
        You have data block on your line. Contact our customer support for more
        information.
      </p>
    </div>
  </div>
  <div v-else-if="isDataBlocked && isPostpaid === 'false'">
    <p class="text-center" style="color: #5bc2cf">
      Your data will be reset on {{ getPrepaidResetDate() }}
    </p>
    <div class="box">
      <img width="140px" height="140px" src="../assets/warning.png" alt="warning" />
      <h5 class="ml-3" style="color: #ff8c29; font-weight: 500">
        You have reached your Data Limit
      </h5>
    </div>
    <div class="text-center">
      <!-- add data button  -->
      <div v-if="step === 0">
        <button style="padding: 5px 20px" v-show="step === 0" class="mybtn-blue" @click="openTheConfirmationMsgBox">
          add more data
        </button>
      </div>
      <!-- add data step 1 -->
      <div v-show="step === 1" class="animate__animated animate__fadeIn">
        <div class="d-flex flex-row justify-content-center align-items-center text-main">
          <i style="font-size: 35px" class="fas fa-minus-circle mx-5"
            :class="selectedData > 1 ? 'text-main' : 'text-grey'" @click="selectedData > 1 ? selectedData-- : null"></i>
          <div class="text-center">
            <div style="font-size: 50px">{{ selectedData }}</div>
            <div style="font-size: 25px; margin-top: -10px">GB</div>
          </div>
          <i style="font-size: 35px" class="fas fa-plus-circle mx-5"
            :class="selectedData < 5 ? 'text-main' : 'text-grey'" @click="selectedData < 5 ? selectedData++ : null"></i>
        </div>
      </div>
      <!-- add data step 2 -->
      <div v-show="step === 2">
        <div class="mb-4 text-center animate__animated animate__fadeIn">
          <div class="dataStyle">{{ selectedData }}GB</div>
          <p>
            Data Top Up:
            <span class="w-500">${{ dataAmount.amount }}</span>
          </p>
          <p v-show="gst !== 0">
            HST/GST ({{ (gst * 100).toFixed(0) + "%" }}):
            <span class="w-500">${{ dataAmount.gst }}</span>
          </p>
          <!-- check if Quebec -> show 9.975% -->
          <p v-show="pst !== 0">
            PST/QST ({{
              parseInt(pst * 100) === 9
                ? (pst * 100).toFixed(3) + "%"
                : (pst * 100).toFixed(0) + "%"
            }})
            <span class="w-500">${{ dataAmount.pst }}</span>
          </p>
          <p v-show="svcFee !== 0">
            Service Fee:
            <span class="w-500">${{ roundTheNumber(svcFee) }}</span>
          </p>
          <p v-show="currbal !== 0">
            Current Balance:
            <span class="w-500">${{ roundTheNumber(currbal) }}</span>
          </p>
          <p class="w-500 text-main" style="font-size: 18px">
            Total: ${{ this.totalAmount }} CAD
          </p>
        </div>
      </div>
      <!-- buttons group  -->
      <div v-show="step >= 1">
        <div class="d-flex flex-row justify-content-center align-items-center animate__animated animate__fadeIn mt-4">
          <img @click="step--" class="mx-3 buttonStyle" src="../assets/left.png" width="20px" height="20px"
            alt="left icon" />
          <div v-if="step !== 2">
            <button style="padding: 5px 30px" class="mybtn-blue" @click="step++">
              next
            </button>
          </div>
          <div v-else>
            <button style="padding: 5px 20px" class="mybtn" @click="handleTopUp()">
              confirm
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// const UPDATE_DATE = 2;
const RED_ALERT = 0.8;
const YELLO_ALERT = 0.5;
const DATAUSAGE_ZERO = 99999999;
const DATACAPACITY_UNLIMITED = 95367;
let styleColor = "green";
let fontColor = "green";
export default {
  data() {
    return {
      dataCapacity: "",
      currentPlan: "",
      dataUsage: "",
      carrier: "",
      dataOverage: "",
      isDataBlocked: false,
      postpaidResetDate: "",
      // TOP UP data fields
      step: 0,
      totalAmount: 0,
      // data display
      dataUsed: 0,
      dataTotal: 0,
      dataPercentage: 0,
      // selected data ? GB
      selectedData: 1,
      // data amount display in step 2 and payment
      dataAmount: {
        amount: 0,
        pst: 0,
        gst: 0,
        total: 0,
      },
      // factor data from prepaid/prepaiduserinfo api
      edate_curr: "",
      currbal: 0,
      svcFee: 0,
      pfId: "",
      pst: 0,
      gst: 0,
      dataRate: 0,
      currency: "",
      // for postpaid topup:
      topUpCapable: false,
      topupOverage: "",
      isRogers: false,
    };
  },
  props: {
    isPostpaid: String,
  },
  watch: {
    step(value) {
      value === 2 ? this.handleChooseTopUpData() : null;
    },
  },
  mounted() {
    this.getClientInfo().then(() => {
      //this.dataUsage = 1600;
      //this.dataCapacity = 95367;
      if (this.dataUsage == DATAUSAGE_ZERO) this.dataUsage = 0;

      let usagePerenctage = this.dataUsage / 1024 / this.dataCapacity;
      usagePerenctage = usagePerenctage > 1.0 ? 1.0 : usagePerenctage;

      const RED = "rgba(255,0,0,0.2)";
      const YELLOW = "rgba(255,255,0,0.2)";
      const GREEN = "rgba(0,255,0,0.2)";
      styleColor =
        usagePerenctage >= RED_ALERT
          ? RED
          : usagePerenctage >= YELLO_ALERT
            ? YELLOW
            : GREEN;
      fontColor =
        usagePerenctage >= RED_ALERT
          ? "red"
          : usagePerenctage >= YELLO_ALERT
            ? "gold"
            : "green";
      this.canvasDraw(usagePerenctage);
    });
    this.getPrepaidUserInfo();
  },
  methods: {
    canvasDraw(usage) {
      const canvas = document.getElementById("usageGraph");

      if (canvas) {
        const ctx = canvas.getContext("2d");
        if (this.isRogers) {
          ctx.beginPath();
          ctx.arc(75, 75, 60, -0.5 * Math.PI, (2 * usage - 0.5) * Math.PI);
          ctx.strokeStyle = styleColor;
          ctx.lineWidth = 5;
          ctx.stroke();
          ctx.closePath();
        }

        ctx.beginPath();
        ctx.arc(75, 75, 53, 0, 2 * Math.PI);
        ctx.fillStyle = styleColor;
        ctx.fill();
        ctx.closePath();

        ctx.beginPath();
        // ctx.font = "36px Arial bolder";
        ctx.font = "26px Arial bolder";
        ctx.fillStyle = fontColor;
        let xPosition = this.isRogers ? (usage >= 1.0 ? 45 : 50) : 40;

        ctx.fillText(
          this.isRogers ? this.getFormatUsage(usage * 100) : "N/A",
          xPosition,
          75
        );
        ctx.closePath();

        ctx.beginPath();
        ctx.font = "12px Arial bolder";
        ctx.fillStyle = fontColor;
        ctx.fillText("%", this.isRogers ? usage > 0.01 ? 90 : 100 : 90, 75);
        ctx.fillText("used", this.isRogers ? usage > 0.01 ? 90 : 100 : 90, 85);
        ctx.closePath();

        ctx.restore();
      }
    },
    // keep 2 decimal
    roundTheNumber(num) {
      let newNum = (Math.round(num * 100) / 100).toFixed(2);
      // the returned value is string type
      return newNum;
    },
    handleChooseTopUpData() {
      // calculate the amount, gst, pst and total money
      this.dataAmount.amount = this.dataRate * this.selectedData;
      this.dataAmount.gst = this.dataAmount.amount * this.gst;
      this.dataAmount.pst = this.dataAmount.amount * this.pst;
      this.dataAmount.total =
        this.dataAmount.amount + this.dataAmount.gst + this.dataAmount.pst;
      // keep 2 decimal for all amount
      this.dataAmount.amount = this.roundTheNumber(this.dataAmount.amount);
      this.dataAmount.gst = this.roundTheNumber(this.dataAmount.gst);
      this.dataAmount.pst = this.roundTheNumber(this.dataAmount.pst);
      this.dataAmount.total = this.roundTheNumber(this.dataAmount.total);
      this.totalAmount =
        Number(this.dataAmount.total) +
        (this.isPostpaid ? 0 : this.currbal) +
        this.svcFee;
      // set minimum payemnt amount to 0.01
      if (this.totalAmount < 0.01) {
        this.totalAmount = 0.01;
      }
      this.totalAmount = this.roundTheNumber(this.totalAmount);
      console.log("ENTERED handleChooseTopUpData");
    },
    handleTopUpPostpaid() {
      let data = {
        systag: "acctarea/topup",
        cnum: this.$store.state.login.cnum,
        amount: this.totalAmount, // including the tax, current balance and service fee
        paymentMethod: "", // update the paymentMethod in the payment page
        tp_capacity: this.selectedData,
        ex_subtotal: 0, // 0 means no extension
        ex_sdate: "",
        ex_edate: "",
        duration: 0,
        currency: "CAD",
      };

      this.$store.commit("setpostpaidServiceData", data);
      this.$router.push("/payment");
    },
    handleTopUp() {
      let data = {
        systag: "acctarea/topup",
        cnum: this.$store.state.login.cnum,
        amount: this.totalAmount, // including the tax, current balance and service fee
        paymentMethod: "", // update the paymentMethod in the payment page
        tp_capacity: this.selectedData,
        ex_subtotal: 0, // 0 means no extension
        ex_sdate: "",
        ex_edate: "",
        duration: 0,
        currency: "CAD",
      };

      this.$store.commit("setprepaidServiceData", data);
      this.$router.push("/prepaid-payment");
    },
    getClientInfo() {
      return this.$axios
        .get("Account/AccountInfo")
        .then((response) => {
          let data = JSON.parse(response.data);
          this.topupOverage = data.TopupOverage;
          this.dataCapacity = data.DataCapacity;
          this.currentPlan = data.CurrentPlan;
          this.dataUsage = data.DataUsage;
          this.carrier = data.Carrier;
          this.currbal = data.CurrentBalance;
          this.dataOverage = data.DataOverage;
          this.isDataBlocked = data.isDataBlocked;
          this.topUpCapable = data.TopupCapable;
          this.topUpRate = data.TopupRate;
          this.gst = data.CurrentGst;
          this.pst = data.CurrentPst;
          this.dataRate = data.TopupRate;
          this.isRogers =
            [
              "rogers",
              "rogers-high data",
              "rogers-voice only",
              "rogers-data only",
            ].indexOf(data.Carrier.toLowerCase()) !== -1;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getPrepaidUserInfo() {
      this.$axios
        .get("prepaid/PrepaidUserInfo")
        .then((response) => {
          let data = JSON.parse(response.data);
          // console.log(data);
          this.edate_curr = data.edate_curr;
          this.currbal = data.currbal;
          this.svcFee = data.svcFee;
          // transfer data format from KB -> MB
          this.dataUsed = data.usage / 1024;
          this.dataTotal = data.capacity / 1024;
          this.dataPercentage = Math.floor(
            (this.dataUsed / this.dataTotal) * 100
          );
          // in Canada, some provinces may mix pst into gst
          this.gst = data.gst;
          this.pst = data.pst;
          this.pfId = data.pfId;
          this.dataRate = data.datarate;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getFormatData(data) {
      let r = data;
      let formattedData = "";
      while (r >= 1000) {
        let remains = r % 1000;
        remains =
          remains >= 100
            ? remains
            : remains >= 10
              ? "0" + remains
              : remains >= 0
                ? "00" + remains
                : "000";
        formattedData = formattedData + "," + remains;
        r = Math.floor(r / 1000);
      }
      formattedData = r + "" + formattedData;
      return formattedData;
    },
    getFormatUsage(usagePerenctage) {
      let str = ""; //字符串累加
      const save = usagePerenctage > 1 ? 1 : 10;
      str = (Math.round(usagePerenctage * save) / save)
        .toFixed(2)
        .toString()
        .replace(/(\d)(?=(\d{3})+\.)/g, function ($0, $1) {
          return $1 + ",";
        });
      return Number(str); //字符串=>数组=>反转=>字符串
    },
    // getDate() {
    //   const now = new Date();
    //   const year = now.getFullYear();
    //   const day = now.getDate();
    //   const month =
    //     day >= UPDATE_DATE ? now.getMonth() + 2 : now.getMonth() + 1;
    //   const monthString = month < 10 ? "0" + month : month;
    //   const dateString = UPDATE_DATE < 10 ? "0" + UPDATE_DATE : UPDATE_DATE;

    //   return year + "-" + monthString + "-" + dateString;
    // },
    getUsedDataString(onlyTotalCapacity = false) {
      let usedData = "🥲 Data usage currently unavailable";
      if (this.isRogers) {
        usedData =
          this.dataUsage > 1024
            ? (this.dataUsage / 1024).toFixed(2) + " GB "
            : this.getFormatData(this.dataUsage) + ".00 MB ";
      }
      const capacityString =
        this.dataCapacity === DATACAPACITY_UNLIMITED
          ? "Unlimited"
          : (this.dataCapacity / 1.0).toFixed(2) + " GB";

      return onlyTotalCapacity ? " / " + capacityString : usedData + " / " + capacityString;
    },
    getPostpaidResetDate() {
      let today = new Date();
      let formattedDate;

      // CHECK FOR 1st DAY OF MONTH
      if (today.getDate() === 1) {
        let month = parseInt(today.getMonth()) + 1;
        let monthString = month.toString();
        if (monthString.length === 1) monthString = "0" + monthString;
        formattedDate = today.getFullYear() + "-" + monthString + "-" + "02";
        return formattedDate;
      }

      // all logic for incrementing the year / month
      let newYear = today.getFullYear();
      let newMonth = today.getMonth() + 2;

      if (newMonth === 13) {
        newMonth = 1;
        newYear = newYear + 1;
      }

      let newMonthString = newMonth.toString();

      if (newMonthString.length === 1) {
        newMonthString = "0" + newMonthString;
      }

      formattedDate = `${newYear}-${newMonthString}-02`;
      this.postpaidResetDate = formattedDate;
      return formattedDate;
    },
    getPrepaidResetDate() {
      let date = new Date(this.edate_curr);
      date.setDate(date.getDate() + 1);

      return date.toISOString().substring(0, 10);
    },
    openTheConfirmationMsgBoxPostpaid() {
      this.$confirm(
        `Your data will be reset on ${this.postpaidResetDate}. Do you still want to Top Up?`,
        "Confirmation",
        {
          confirmButtonText: "Yes",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      )
        .then(() => {
          this.step = 1;
        })
        .catch(() => {
          console.log("cancelled");
        });
    },
    openTheConfirmationMsgBox() {
      this.$confirm(
        `Your current term end date is ${this.edate_curr}. Do you still want to Top Up?`,
        "Confirmation",
        {
          confirmButtonText: "Yes",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      )
        .then(() => {
          this.step = 1;
        })
        .catch(() => {
          console.log("cancelled");
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.Graph {
  display: flex;
}

.UsedInfo {
  font: 22px Arial bolder;
  height: 180px;
  padding-top: 40px;
  display: flex;
  flex-direction: row;
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
  align-items: flex-end;
}

.box {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.dataStyle {
  display: inline-block;
  font-size: 24px;
  margin-bottom: 30px;
  padding: 5px 10px;
  border-radius: 2px;
  background-color: #e1f6f8;
  color: #36c2cf;
}

.data-usage-string {
  margin-left: 20px;
}

.data-not-avail-text {
  font-size: 12px;
}


@media (max-width: 768px) {
  .Graph {
    display: block;
    margin-bottom: 20px;
  }

  .UsedInfo {
    padding-top: 0px;
  }
}
</style>
