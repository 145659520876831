<template>
  <div>
    <div class="d-flex flex-row justify-content-centet align-items-center px-2">
      <h4 class="text-grey m-0">Total Balance:</h4>
      <h3 class="m-3 text-center m-0">$ {{ totalAmount }}</h3>
    </div>
    <div class="p-2" v-for="el in accData" :key="el.Cnum">
      <div class="mycard shadow p-3 justify-content-between align-items-center">
        <b-row class="my-1">
          <b-col>
            <h5 class="m-0">
              {{ el.FirstName + " " + el.LastName }}
            </h5>
          </b-col>
          <b-col>
            <h5 class="m-0">$ {{ el.Balance.toFixed(2) }}</h5>
          </b-col>
        </b-row>
        <b-row class="my-1">
          <b-col>
            <p class="m-0" style="color:#D2D2D2">
              {{ processNumberFormat(el.Pnum) }}
            </p>
          </b-col>
          <b-col>
            <p class="m-0" style="color:#D2D2D2">
              {{ el.AccountType === "prepaid" ? "Prepaid" : "Monthly" }}
            </p>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    accData() {
      return this.$store.state.mutilLinesAccounts;
    },
    totalAmount() {
      let sum = 0;
      this.accData.forEach((el) => {
        if (el.Balance >= 0.01) {
          sum += el.Balance;
        }
      });
      return sum.toFixed(2);
    },
  },
  methods: {
    processNumberFormat(num) {
      return (
        "+1 (" + num.slice(0, 3) + ") " + num.slice(3, 6) + " - " + num.slice(6)
      );
    },
  },
};
</script>

<style lang="scss" scoped></style>
